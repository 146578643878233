import { MODAL_WIDTH } from 'utils/constants';
import { Button, Col, Modal, Row } from 'antd';
import { ReactNode, useState } from 'react';

import styles from './styles.module.scss';

interface IAntModalConfirm {
  onOk?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  okText?: string;
  okButtonProps?: () => void;
  cancelText?: string;
  cancelButtonProps?: () => void;
  title?: string;
  children?: ReactNode;
  confirmLoading?: boolean;
  className?: string;
}

export const AntModalConfirm = ({
  onOk,
  onCancel,
  onClose,
  okText,
  okButtonProps,
  cancelText,
  cancelButtonProps,
  title,
  children,
  confirmLoading,
  className,
}: IAntModalConfirm) => {
  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    try {
      setLoading(true);
      await onOk?.();
      onClose?.();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className={`${styles.antModalConfirm} ${className || ''} `}>
      <div className="text-center text-md">{title}</div>
      {!!children && <div className="text-center mt-2">{children}</div>}

      <Row gutter={[12, 0]} className="mt-6 ">
        {!!onCancel && (
          <Col flex={1} span={12}>
            <Button
              block
              onClick={() => {
                onCancel();
                onClose?.();
              }}
              {...cancelButtonProps}
              size="large"
            >
              {cancelText || 'Cancel'}
            </Button>
          </Col>
        )}
        {!!onOk && (
          <Col flex={1} span={!onCancel ? 24 : 12}>
            <Button
              type="primary"
              block
              onClick={() => onConfirm()}
              {...okButtonProps}
              loading={confirmLoading || loading}
              size="large"
            >
              {okText || 'OK'}
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

interface IModalConfirm extends IAntModalConfirm {
  useModal?: any;
  width?: number;
  content?: ReactNode;
  children?: ReactNode;
  className?: string;
  wrapClassName?: string;
  centered?: boolean;
  closable?: boolean;
  title?: string;
}

export default function ModalConfirm({
  useModal,
  width,
  content,
  children = null,
  className = '',
  wrapClassName = '',
  centered = false,
  closable = true,
  ...props
}: IModalConfirm) {
  const modal = (useModal ?? Modal).confirm({
    closable,
    icon: null,
    width: width ?? MODAL_WIDTH.MEDIUM,
    className: `${styles.modalCustom} ${className}`,
    wrapClassName,
    centered,
    content: content ?? (
      <AntModalConfirm onClose={() => modal.destroy()} {...props}>
        {children}
      </AntModalConfirm>
    ),
  });

  return modal;
}
