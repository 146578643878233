import urlcat from 'urlcat';

export const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL || '';

function getAPI(endpoint: string) {
  return urlcat(ADMIN_API_URL, endpoint);
}

export const API_PREFIX_V1 = '/api/v1/admin';

export const API_LOGIN = getAPI(`${API_PREFIX_V1}/login`);
export const API_ARTIFACT = `/chapters/:id/artifact`;
export const API_DOCUMENTS = 'chapters/:id/documents';
export const API_UPDATE_DELETE_DOCUMENT = 'documents';
