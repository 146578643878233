import { Card, Empty } from '@pankod/refine-antd';
import { useState } from 'react';

import HeaderBox from './components/HeaderBox';
import { TABS_KEY } from './utils/constant';

import styles from './styles.module.scss';
import Tools from './components/Tools';

function ChapterDetail() {
  const [tabActive, setTabActive] = useState<string>(TABS_KEY.tools);

  function handleChangeTab(activeKey: string) {
    setTabActive(activeKey);
  }

  const renderByTabKey = {
    [TABS_KEY.tools]: <Tools />,
  };

  return (
    <>
      <Card className={styles.detailPageCard}>
        <HeaderBox handleChangeTab={handleChangeTab} tabActive={tabActive} />
      </Card>

      <Card>
        {renderByTabKey[tabActive] ?? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Card>
    </>
  );
}

export default ChapterDetail;
