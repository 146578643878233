import {
  Button,
  Form,
  Input,
  Space,
  Icons,
  Typography,
  Row,
  Col,
} from '@pankod/refine-antd';
import { useCreate } from '@pankod/refine-core';
import urlcat from 'urlcat';

import { API_DOCUMENTS } from 'api';

import { DOCUMENTS_TYPE } from 'Pages/ChapterDetail/utils/constant';
import { useParams } from '@pankod/refine-react-router-v6';

interface IProps {
  refetch: () => void;
  onCancel: () => void;
}

type TFormDocumentList = {
  document_list: {
    name: string;
    link: string;
  }[];
};

type TDocumentList = {
  name: string;
  object_data: string;
};

interface IPayload {
  type: string;
  document_list: TDocumentList[];
}

function FormAddDocuments(props: IProps) {
  const { onCancel, refetch } = props;

  const { id: chapterId } = useParams();

  const url: string = urlcat(`${API_DOCUMENTS}`, {
    id: chapterId,
  });

  const { mutate: create, isLoading } = useCreate();

  function handleSubmit(values: TFormDocumentList) {
    const documentList: TDocumentList[] = values.document_list.map((item) => {
      return {
        name: item.name,
        object_data: JSON.stringify({ link: item.link }),
      };
    });

    const payload: IPayload = {
      type: DOCUMENTS_TYPE.WIKI,
      document_list: documentList,
    };

    create({
      resource: url,
      values: payload,
      successNotification: () => {
        onCancel();
        refetch();

        return {
          message: 'Success',
          description: 'Create documents successfully',
          type: 'success',
        };
      },
      errorNotification: () => {
        return {
          message: 'Error',
          description: 'Create documents failed',
          type: 'error',
        };
      },
    });
  }

  function isValidURL(value: string) {
    var res = value.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    );

    return res !== null;
  }

  return (
    <div>
      <Typography.Title level={3} className="m-0">
        Create Documents
      </Typography.Title>

      <Form className="my-5" onFinish={handleSubmit} disabled={isLoading}>
        <Form.List name="document_list">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={8} align="middle">
                  <Col span={10}>
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[{ required: true, message: 'Please input name' }]}
                    >
                      <Input placeholder="Name" size="large" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      name={[name, 'link']}
                      rules={[
                        () => ({
                          validator: (_, value) => {
                            if (!value) {
                              return Promise.reject(
                                new Error('Please input link')
                              );
                            }

                            if (!isValidURL(value)) {
                              return Promise.reject(
                                new Error('Requested url is not valid')
                              );
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input placeholder="Link" size="large" />
                    </Form.Item>
                  </Col>

                  <Col span={2}>
                    <div className="mb-6">
                      <Icons.MinusCircleOutlined onClick={() => remove(name)} />
                    </div>
                  </Col>
                </Row>
              ))}

              <Form.Item className="my-4">
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<Icons.PlusOutlined />}
                >
                  Add documents
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <div className="text-end">
          <Space>
            <Button size="large" onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>

            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={isLoading}
            >
              Save
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
}

export default FormAddDocuments;
