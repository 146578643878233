import { memo } from 'react';

import ChapterInformation from '../ChapterInformation';
import TabsOverview from '../TabsOverview';

interface IProps {
  tabActive: string;
  handleChangeTab: (key: string) => void;
}

function HeaderBox(props: IProps) {
  const { tabActive, handleChangeTab } = props;

  return (
    <>
      <ChapterInformation />
      <TabsOverview handleChangeTab={handleChangeTab} tabActive={tabActive} />
    </>
  );
}

export default memo(HeaderBox);
