import Documents from './components/Documents';

function Tools() {
  return (
    <div>
      <Documents />
    </div>
  );
}

export default Tools;
