import { useRef } from 'react';
import { Button, Space, Typography } from '@pankod/refine-antd';
import { useApiUrl, useCustomMutation } from '@pankod/refine-core';
import { useParams } from '@pankod/refine-react-router-v6';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import urlcat from 'urlcat';

import { API_ARTIFACT } from 'api';

import styles from './styles.module.scss';

interface IProps {
  edit: string;
  title: string;
  content: string;
  onCancel: () => void;
  refetch?: () => void;
}

interface IPayload {
  mission?: string;
  principle?: string;
}

function UpdateContent(props: IProps) {
  const { edit, title, onCancel, content, refetch } = props;
  const editorRef: any = useRef(null);

  const apiUrl = useApiUrl();
  const params = useParams();

  const { mutate, isLoading } = useCustomMutation<IPayload>();

  const valueEmpty: any = {
    '<p><br></p>': '<p><br></p>',
    '<h1><br></h1>': '<h1><br></h1>',
    '<h2><br></h2>': '<h2><br></h2>',
    '<h3><br></h3>': '<h3><br></h3>',
    '<h4><br></h4>': '<h4><br></h4>',
    '<h5><br></h5>': '<h5><br></h5>',
    '<h6><br></h6>': '<h6><br></h6>',
  };

  function handleSubmit() {
    const url = urlcat(`${apiUrl}${API_ARTIFACT}`, { id: params.id });
    let value = editorRef?.current?.getInstance().getHTML();

    if (valueEmpty[value]) {
      value = '';
    }

    mutate({
      url,
      method: 'put',
      values: {
        [edit]: value,
      },

      successNotification: () => {
        refetch?.();
        onCancel();

        return {
          message: 'Success',
          description: `Update ${edit} successfully`,
          type: 'success',
        };
      },

      errorNotification: () => {
        return {
          message: 'Error',
          description: `Update ${edit} failed`,
          type: 'error',
        };
      },
    });
  }

  return (
    <div className={styles.wrapper}>
      <Typography.Title level={2}>Update {title}</Typography.Title>

      <div className="mb-5">
        <Editor
          initialValue={content}
          previewStyle="vertical"
          height="600px"
          initialEditType="markdown"
          useCommandShortcut={true}
          ref={editorRef}
        />
      </div>

      <div className="text-end">
        <Space size="middle">
          <Button onClick={onCancel} size="large">
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={handleSubmit}
            loading={isLoading}
          >
            Save
          </Button>
        </Space>
      </div>
    </div>
  );
}

export default UpdateContent;
