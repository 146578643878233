import {
  Button,
  Form,
  Input,
  Space,
  Typography,
  Row,
  Col,
} from '@pankod/refine-antd';
import { useUpdate } from '@pankod/refine-core';

import { API_UPDATE_DELETE_DOCUMENT } from 'api';

import { DOCUMENTS_TYPE } from 'Pages/ChapterDetail/utils/constant';

import { IDocuments } from 'models/documents.types';

interface IProps {
  refetch: () => void;
  onCancel: () => void;
  initialValues: IDocuments;
}

type TFormDocumentList = {
  name: string;
  link: string;
  id: string;
};

type TDocumentList = {
  name: string;
  object_data: string;
};

function FormUpdateDocuments(props: IProps) {
  const { onCancel, refetch, initialValues } = props;

  const { mutate: update, isLoading } = useUpdate();

  function handleSubmit(values: TFormDocumentList) {
    const payload: TDocumentList = {
      name: values.name,
      object_data: JSON.stringify({ link: values.link }),
    };

    update({
      resource: API_UPDATE_DELETE_DOCUMENT,
      values: payload,
      id: values.id,
      successNotification: () => {
        onCancel();
        refetch();

        return {
          message: 'Success',
          description: 'Update document successfully',
          type: 'success',
        };
      },
      errorNotification: () => {
        return {
          message: 'Error',
          description: 'Update documents failed',
          type: 'error',
        };
      },
    });
  }

  function isValidURL(value: string) {
    var res = value.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    );

    return res !== null;
  }

  const initial = {
    id: initialValues.id,
    name: initialValues?.name,
    link: initialValues?.object_data.link,
  };

  return (
    <div>
      <Typography.Title level={3} className="m-0">
        Update Documents
      </Typography.Title>

      <Form
        className="my-5"
        onFinish={handleSubmit}
        disabled={isLoading}
        initialValues={initial}
      >
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>

        <Row gutter={8} align="middle">
          <Col span={10}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Please input name' }]}
            >
              <Input placeholder="Name" size="large" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="link"
              rules={[
                () => ({
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(new Error('Please input link'));
                    }

                    if (!isValidURL(value)) {
                      return Promise.reject(
                        new Error('Requested url is not valid')
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input placeholder="Link" size="large" />
            </Form.Item>
          </Col>
        </Row>

        <div className="text-end">
          <Space>
            <Button size="large" onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>

            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={isLoading}
            >
              Save
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
}

export default FormUpdateDocuments;
