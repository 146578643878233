import React from 'react';

import { LayoutProps } from '@pankod/refine-core';
import { AntdLayout, Grid } from '@pankod/refine-antd';
import Breadcrumb from 'components/Breadcrumb';

export const Layout: React.FC<LayoutProps> = ({ children, Sider, Header }) => {
  const breakpoint = Grid.useBreakpoint();
  return (
    <AntdLayout style={{ minHeight: '100vh', flexDirection: 'row' }}>
      {Sider && <Sider />}

      <AntdLayout>
        {Header && <Header />}

        <AntdLayout.Content>
          <div className={breakpoint.sm ? 'p-6' : 'p-3'}>
            <Breadcrumb />
            {children}
          </div>
        </AntdLayout.Content>
      </AntdLayout>
    </AntdLayout>
  );
};
