export const ACCESS_TOKEN = 'admin-chapter-token';

export const MODAL_WIDTH = {
  SMALL: 300,
  MEDIUM: 500,
  LARGE: 900,
  LARGE_XL: 1200,
};

export const STRING_EMPTY = '---';

export const COLOR = {
  YELLOW: '#f3b130',
  RED: '#ff555b',
  STEAL_100: '#33b3aa',
  STEAL_400: '#2AAEB7',
  ORANGE_1: '#F2772E',
  GRAY_500: '#a3a3a3',
};
