const formField = {
  username: {
    name: 'username',
    label: 'Username',
    placeholder: 'Username',
    required: true,
    errMsg: 'Please input username',
  },
  password: {
    name: 'password',
    label: 'Password',
    placeholder: 'Password',
    required: true,
    errMsg: 'Please input password',
  },
};

export default formField;
