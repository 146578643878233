import { Refine } from '@pankod/refine-core';
import { notificationProvider, ErrorComponent } from '@pankod/refine-antd';
import '@pankod/refine-antd/dist/reset.css';

import routerProvider from '@pankod/refine-react-router-v6';
import { useTranslation } from 'react-i18next';

import { ColorModeContextProvider } from 'contexts';
import { ADMIN_API_URL, API_PREFIX_V1 } from 'api';
import { Title, Header, Sider, Footer, Layout } from 'components/layout';
import AuthenticationPage from 'Pages/AuthenticationPage';
import resource from 'resource';
import ListChapter from 'Pages/ListChapter';
import ChapterDetail from 'Pages/ChapterDetail';

import { authProvider } from './providers/auth';
import { dataProvider } from './providers/data';

import './uikit/uikit.css';

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <ColorModeContextProvider>
      <Refine
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              path: resource.ADMIN_LOGIN,
              element: <AuthenticationPage />,
            },
          ],
        }}
        i18nProvider={i18nProvider}
        authProvider={authProvider}
        LoginPage={AuthenticationPage}
        resources={[
          {
            name: resource.ADMIN_LIST_CHAPTER,
            list: ListChapter,
            show: ChapterDetail,
            options: { label: 'Chapter management' },
          },
        ]}
        dataProvider={dataProvider(ADMIN_API_URL, API_PREFIX_V1)}
        notificationProvider={notificationProvider}
        catchAll={<ErrorComponent />}
        Title={Title}
        Header={Header}
        Sider={Sider}
        Footer={Footer}
        Layout={Layout}
        options={{
          reactQuery: {
            clientConfig: {
              defaultOptions: {
                queries: {
                  staleTime: Infinity,
                  retry: false,
                },
              },
            },
          },
        }}
      />
    </ColorModeContextProvider>
  );
}

export default App;
