import { memo } from 'react';
import { Col, Row, Spin, Typography } from 'antd';
import urlcat from 'urlcat';
import { isEmpty } from 'lodash';
import { HttpError, useCustom, useShow, useApiUrl } from '@pankod/refine-core';
import { nanoid } from 'nanoid';

import { API_ARTIFACT } from 'api';

import { IArtifact, IChapterDetail } from 'models';

import { STRING_EMPTY } from 'utils/constants';

import CardDetail from '../CardDetail';

export type TFieldArtifact = 'mission' | 'principle';

function ChapterInformation() {
  const apiUrl = useApiUrl();
  const { queryResult } = useShow<IResponse<IChapterDetail>, HttpError>();

  const chapterDetail = queryResult.data?.data.result;
  const url = !isEmpty(chapterDetail)
    ? urlcat(`${apiUrl}${API_ARTIFACT}`, { id: chapterDetail.id })
    : '';

  const { data, isLoading, refetch } = useCustom<IResponse<IArtifact>>({
    url,
    method: 'get',
    queryOptions: {
      enabled: chapterDetail?.id !== undefined,
    },
  });

  const dataArtifact = data?.data.result;
  function getDescriptionArtifact(field: TFieldArtifact) {
    return dataArtifact?.[field] || '';
  }

  const mergeDataDescription =
    dataArtifact?.expertise_scope && dataArtifact.knowledge_area
      ? [dataArtifact.expertise_scope, dataArtifact.knowledge_area].flat()
      : [];

  return (
    <Spin spinning={isLoading}>
      <div className="mb-5">
        <Typography.Title level={2} className="mb-5 mr-3">
          {chapterDetail?.name || STRING_EMPTY}
        </Typography.Title>

        <Row gutter={16}>
          <Col span={8}>
            <CardDetail
              edit="mission"
              title="Mission"
              description={getDescriptionArtifact('mission')}
              refetch={refetch}
            />
          </Col>

          <Col span={8}>
            <CardDetail
              edit="principle"
              title="Principle"
              description={getDescriptionArtifact('principle')}
              refetch={refetch}
            />
          </Col>

          <Col span={8}>
            <CardDetail
              title="Knowledge Area & Expertise Scope"
              description={mergeDataDescription.map((item) => ({
                ...item,
                rowKey: nanoid(),
              }))}
              detailDescription={{
                expertise_scope: dataArtifact?.expertise_scope,
                knowledge_area: dataArtifact?.knowledge_area,
              }}
            />
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default memo(ChapterInformation);
