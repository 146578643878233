import { useEffect, useState } from 'react';
import { Typography, Modal, Empty, Divider } from 'antd';
import { isEmpty, isString } from 'lodash';
import * as DOMPurify from 'dompurify';

import { IExpertiseScope, IKnowledgeArea } from 'models/artifact.types';

import ModalConfirm from 'components/ModalConfirm';
import { MODAL_WIDTH } from 'utils/constants';

import UpdateContent from './components/UpdateContent';

import styles from './styles.module.scss';

interface IDesc extends IExpertiseScope, IKnowledgeArea {
  rowKey: string;
}

interface IDetailDesc {
  expertise_scope?: IExpertiseScope[];
  knowledge_area?: IKnowledgeArea[];
}

type TDescription = string | IDesc[];
interface IProps {
  title: string;
  description: TDescription;
  edit?: 'mission' | 'principle';
  detailDescription?: IDetailDesc;
  refetch?: () => void;
}

const MAX_LENGTH_LIST_ITEM = 4;
const MAX_HEIGHT_ELLIPSIS = 86;

function CardDetail(props: IProps) {
  const { title, description, edit, detailDescription, refetch } = props;

  const [showSeeMore, setShowSeeMore] = useState<boolean>(false);

  const [modal, contextHolder] = Modal.useModal();

  const symbol = <span className="text-teal-400 cursor-pointer">See more</span>;
  const safeHTML = DOMPurify.sanitize(isString(description) ? description : '');

  function checkEllipsisList() {
    if (!Array.isArray(description)) return [];

    if (description.length > MAX_LENGTH_LIST_ITEM) {
      return description.slice(0, MAX_LENGTH_LIST_ITEM);
    }

    return description;
  }

  useEffect(() => {
    const sanitizeHTML = document.getElementById(`sanitizeHTML-${edit}`);

    if (sanitizeHTML) {
      setShowSeeMore(sanitizeHTML.scrollHeight > MAX_HEIGHT_ELLIPSIS);
    }
  }, [edit, description]);

  function handleSeeMoreListItem() {
    ModalConfirm({
      useModal: modal,
      content: (
        <>
          {detailDescription?.expertise_scope ? (
            <>
              <Typography.Title level={2}>Expertise Scope</Typography.Title>

              {detailDescription.expertise_scope.map((item) => (
                <li key={item.id}>
                  {item.name} ({item.type})
                </li>
              ))}
            </>
          ) : null}

          <Divider />

          {detailDescription?.knowledge_area ? (
            <>
              <Typography.Title level={2}>Knowledge Area</Typography.Title>

              {detailDescription.knowledge_area.map((item) => (
                <li key={item.id}>{item.name}</li>
              ))}
            </>
          ) : null}
        </>
      ),
    });
  }

  function showMoreDescription() {
    ModalConfirm({
      useModal: modal,
      content: (
        <>
          <Typography.Title level={2}>{title}</Typography.Title>

          <p
            dangerouslySetInnerHTML={{
              __html: safeHTML,
            }}
          />
        </>
      ),
    });
  }

  function renderListSymbol() {
    return description.length > MAX_LENGTH_LIST_ITEM ? symbol : null;
  }

  function renderTextSymbol() {
    return showSeeMore ? symbol : null;
  }

  function handleEditContent() {
    const modalUpdateContent = ModalConfirm({
      useModal: modal,
      width: MODAL_WIDTH.LARGE_XL,
      content: (
        <UpdateContent
          edit={edit || ''}
          title={title}
          content={isString(description) ? description : ''}
          onCancel={() => modalUpdateContent.destroy()}
          refetch={refetch}
        />
      ),
    });
  }

  function renderText() {
    return (
      <>
        <div id={`sanitizeHTML-${edit}`}>
          <p
            className="content-sanitizeHTML"
            dangerouslySetInnerHTML={{
              __html: safeHTML,
            }}
          />
        </div>

        <div className="text-end">
          <span onClick={showMoreDescription}>{renderTextSymbol()}</span>
        </div>
      </>
    );
  }

  function renderList() {
    return (
      <ul>
        {checkEllipsisList().map((item) => (
          <li key={item.rowKey}>
            {item.name} {item.type ? `(${item.type})` : null}
          </li>
        ))}

        <li className="text-end">
          <span className="cursor-pointer" onClick={handleSeeMoreListItem}>
            {renderListSymbol()}
          </span>
        </li>
      </ul>
    );
  }

  function renderContent() {
    return (
      <div className={styles.contentDescription}>
        {isString(description) ? renderText() : renderList()}
      </div>
    );
  }

  return (
    <>
      {contextHolder}

      <div className="bg-gray-1 p-4 rounded h-100">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <Typography.Title level={4} className="m-0">
            {title}
          </Typography.Title>

          {edit ? (
            <span
              className="cursor-pointer text-teal-400"
              onClick={handleEditContent}
            >
              Edit
            </span>
          ) : null}
        </div>

        {!isEmpty(description) || description ? (
          renderContent()
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="m-0" />
        )}
      </div>
    </>
  );
}

export default CardDetail;
