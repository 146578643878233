import { useLogin } from '@pankod/refine-core';
import { Button, Col, Form, Input, Row } from '@pankod/refine-antd';

import formField from './utils/formField';

import logo from './assets/images/logo.png';
import styles from './styles.module.scss';

type LoginVariables = {
  username: string;
  password: string;
};

const { username, password } = formField;

const LoginPage = () => {
  const { mutate: login, isLoading } = useLogin<LoginVariables>();

  const onSubmit = (values: LoginVariables) => {
    login(values);
  };

  return (
    <div className={styles.adminLoginPage}>
      <div className="h-100 d-flex align-items-center">
        <div className={styles.content}>
          <Row>
            <Col span={24}>
              <div className={styles.wrapFormLogin}>
                <div className="text-center">
                  {' '}
                  <img
                    style={{ width: '25%', height: '25%', paddingTop: 20 }}
                    src={logo}
                    alt="otable logo"
                  />
                </div>

                <div className="text-center my-3">
                  <h1>Welcome!</h1>
                </div>

                <Form
                  onFinish={onSubmit}
                  layout="vertical"
                  className={styles.formLogin}
                  disabled={isLoading}
                >
                  <Form.Item
                    name={username.name}
                    label={username.label}
                    rules={[
                      { required: username.required, message: username.errMsg },
                    ]}
                  >
                    <Input
                      placeholder={username.placeholder}
                      size="large"
                      className="w-100"
                    />
                  </Form.Item>

                  <Form.Item
                    name={password.name}
                    label={password.label}
                    rules={[
                      { required: password.required, message: password.errMsg },
                    ]}
                  >
                    <Input.Password
                      placeholder={password.placeholder}
                      size="large"
                    />
                  </Form.Item>

                  <div className="text-end">
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      loading={isLoading}
                      className="w-100"
                    >
                      Sign in
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
