interface IKeyBy {
  [key: string]: string;
}

const mappingRoute: IKeyBy = {
  chapters: 'Chapter management',
  show: 'Detail',
};

export default mappingRoute;
