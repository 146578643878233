import { Tabs } from 'antd';
import type { TabsProps } from 'antd';

import { TABS_KEY } from '../../utils/constant';

import styles from './styles.module.scss';

interface IProps {
  tabActive: string;
  handleChangeTab: (key: string) => void;
}

function TabsOverview(props: IProps) {
  const { tabActive, handleChangeTab } = props;

  const items: TabsProps['items'] = [
    {
      key: TABS_KEY.tools,
      label: 'Tools',
      children: null,
    },
  ];

  return (
    <Tabs
      items={items}
      className={styles.tabsOverview}
      onChange={handleChangeTab}
      activeKey={tabActive}
    />
  );
}

export default TabsOverview;
