import { useMemo } from 'react';

import type { MenuProps } from 'antd';
import { Breadcrumb as BreadcrumbAntD, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { HttpError, useList, useNavigation } from '@pankod/refine-core';
import { Link, useLocation } from '@pankod/refine-react-router-v6';

import { IChapter } from 'models';
import resource from 'resource';

import mappingRoute from './utils/mappingRoute';

import styles from './styles.module.scss';

type TRouteMapping = {
  isShowDropdown: boolean;
  key: string;
  name: string;
  path: string;
  id: number;
};

function Breadcrumb() {
  const location = useLocation();
  const routesSelected = useMemo(() => location.pathname, [location]);
  const { show } = useNavigation();

  const { data } = useList<IChapter, HttpError>({
    resource: resource.ADMIN_LIST_CHAPTER,
  });

  const itemsChapter: MenuProps['items'] = (data?.data || []).map((item) => ({
    key: item.chapter.id,
    label: (
      <span onClick={() => show(resource.ADMIN_LIST_CHAPTER, item.chapter.id)}>
        {item.chapter.name}
      </span>
    ),
  }));

  const routeData: string[] = [];
  const routes = routesSelected.split('/').filter((i) => i);

  const routeMapping: TRouteMapping[] = routes
    .map((route) => {
      routeData.push(`/${route}`);

      const currentChapter: any = data?.data?.find(
        (item) => String(item?.chapter?.id) === route
      );

      return {
        id: currentChapter?.chapter.id || -1,
        isShowDropdown: !isEmpty(currentChapter) && itemsChapter.length > 1,
        key: route,
        name: mappingRoute[route] || currentChapter?.chapter?.name || '---',
        path: routeData.join(''),
      };
    })
    .filter((route) => route.key !== 'show');

  return (
    <BreadcrumbAntD className="mb-6">
      {routeMapping.map((route) => {
        return (
          <BreadcrumbAntD.Item key={route.key}>
            {route.isShowDropdown ? (
              <Dropdown
                trigger={['click']}
                menu={{
                  items: itemsChapter.filter((i) => i?.key !== route.id),
                }}
                overlayClassName={styles.dropdownBreadcrumb}
              >
                <Link to={route.path}>
                  <Space size={8}>
                    {route.name}

                    <DownOutlined style={{ fontSize: 10 }} />
                  </Space>
                </Link>
              </Dropdown>
            ) : (
              <Link to={route.path}>{route.name}</Link>
            )}
          </BreadcrumbAntD.Item>
        );
      })}
    </BreadcrumbAntD>
  );
}

export default Breadcrumb;
