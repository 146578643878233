import {
  Card,
  Typography,
  Table,
  Input,
  Row,
  Col,
  Avatar,
  Tooltip,
} from '@pankod/refine-antd';
import { HttpError, useList, useNavigation } from '@pankod/refine-core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { debounce } from 'lodash';

import { IMember, IChapter, TChapter } from 'models';
import resource from 'resource';

function ListChapter() {
  const { show } = useNavigation();

  const { data, isLoading } = useList<IChapter, HttpError>({
    resource: resource.ADMIN_LIST_CHAPTER,
  });

  const response: IChapter[] = useMemo(() => data?.data || [], [data?.data]);
  const [dataSource, setDataSource] = useState<IChapter[]>(response);

  useEffect(() => {
    setDataSource(response);
  }, [response]);

  function getPathRedirect(id: number) {
    return show(resource.ADMIN_LIST_CHAPTER, id);
  }

  function onRowClick(chapter: IChapter) {
    getPathRedirect(chapter.chapter.id);
  }

  const onSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const search = debounce((value: string) => {
        const searchDataSource = dataSource.filter((item) =>
          item?.chapter?.name
            .toLocaleLowerCase()
            .trim()
            .includes(value.toLocaleLowerCase().trim())
        );

        if (!value) {
          return setDataSource(response);
        }

        return setDataSource(searchDataSource);
      }, 500);

      const { value } = event.target;
      search(value);
    },

    [dataSource, response]
  );

  function renderMember(member: IMember) {
    return (
      <div className="d-flex align-items-center">
        {member?.avatar ? (
          <Avatar src={member?.avatar} className="mr-3" />
        ) : null}
        {member?.alias}
      </div>
    );
  }

  const columns: ColumnsType<IChapter> = [
    {
      title: 'Name',
      dataIndex: 'chapter',
      render: (value: TChapter) => {
        return <span className="text-teal-400">{value?.name}</span>;
      },
    },
    {
      title: 'Knowledge champions',
      dataIndex: 'knowledge_champions',
      render: (values: IMember[]) => {
        if (values.length === 1) {
          return renderMember(values[0]);
        }

        return (
          <Avatar.Group maxCount={4}>
            {values?.map((member) => {
              return (
                <Tooltip title={member.name} placement="top">
                  <Avatar src={member?.avatar} />
                </Tooltip>
              );
            })}
          </Avatar.Group>
        );
      },
    },
    {
      title: 'Chapter lead',
      dataIndex: 'chapter_lead',
      render: (values: IMember) => renderMember(values),
    },
  ];

  return (
    <Card>
      <Row gutter={20}>
        <Col span={14}>
          <Typography.Title className="mb-5">Chapter list</Typography.Title>
        </Col>

        <Col span={10}>
          <Input.Search
            size="large"
            placeholder="Search chapter ..."
            onChange={onSearch}
          />
        </Col>
      </Row>

      <Table
        rowKey="id"
        loading={isLoading}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        className="mb-5"
        rowClassName="cursor-pointer"
        onRow={(record) => ({
          onClick: () => onRowClick(record),
        })}
      />
    </Card>
  );
}

export default ListChapter;
