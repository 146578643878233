import {
  Button,
  Table,
  Typography,
  Modal,
  Tooltip,
  Icons,
} from '@pankod/refine-antd';
import { useDelete, useTable } from '@pankod/refine-core';
import { useParams } from '@pankod/refine-react-router-v6';
import urlcat from 'urlcat';

import { IDocuments } from 'models/documents.types';
import { API_DOCUMENTS, API_UPDATE_DELETE_DOCUMENT } from 'api';
import { MODAL_WIDTH, COLOR } from 'utils/constants';
import ModalConfirm from 'components/ModalConfirm';

import FormAddDocuments from '../FormAddDocument';
import FormUpdateDocument from '../FormUpdateDocument';

function Wiki() {
  const [modal, contextModal] = Modal.useModal();

  const { id: chapterId } = useParams();
  const url = urlcat(`${API_DOCUMENTS}`, { id: chapterId });

  const { tableQueryResult } = useTable<IDocuments>({
    resource: url,
  });

  const { data, isLoading, refetch } = tableQueryResult;

  const { mutateAsync: deleteDocs } = useDelete();

  function addMoreDocuments() {
    const modalAddMore = ModalConfirm({
      useModal: modal,
      width: MODAL_WIDTH.LARGE,
      content: (
        <FormAddDocuments
          onCancel={() => modalAddMore.destroy()}
          refetch={refetch}
        />
      ),
    });
  }

  function updateDocument(record: IDocuments) {
    const modalUpdate = ModalConfirm({
      useModal: modal,
      width: MODAL_WIDTH.LARGE,
      content: (
        <FormUpdateDocument
          onCancel={() => modalUpdate.destroy()}
          refetch={refetch}
          initialValues={record}
        />
      ),
    });
  }

  async function confirmDelete(id: number, onClose: () => void) {
    return deleteDocs({
      resource: API_UPDATE_DELETE_DOCUMENT,
      id,
      successNotification: () => {
        refetch();
        onClose();

        return {
          message: 'Success',
          description: 'Update document successfully',
          type: 'success',
        };
      },
      errorNotification: () => {
        return {
          message: 'Error',
          description: 'Update documents failed',
          type: 'error',
        };
      },
    });
  }

  function deleteDocument(id: number) {
    const modalDelete = ModalConfirm({
      useModal: modal,
      title: 'Do you want to delete this item?',
      children: 'This item will be delete immediately',
      onCancel: () => true,
      onOk: async () => await confirmDelete(id, () => modalDelete.destroy()),
    });
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (value: string, record: IDocuments) => {
        return (
          <a
            href={record.object_data.link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-teal-400"
          >
            {value}
          </a>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (_: string, record: IDocuments) => {
        return (
          <>
            <Tooltip title="Edit">
              <Button
                type="text"
                className="text-teal-400"
                onClick={() => updateDocument(record)}
              >
                <Icons.EditOutlined />
              </Button>
            </Tooltip>

            <Tooltip title="Delete">
              <Button
                type="text"
                className="text-teal-400"
                onClick={() => deleteDocument(record.id)}
              >
                <Icons.DeleteTwoTone twoToneColor={COLOR.STEAL_400} />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <div>
      {contextModal}

      <div className="d-flex justify-content-between align-items-center">
        <Typography.Title level={2} className="m-0">
          Documents
        </Typography.Title>

        <Button type="primary" size="large" onClick={addMoreDocuments}>
          Add more
        </Button>
      </div>

      <div className="my-5">
        <Table
          rowKey="id"
          pagination={false}
          loading={isLoading}
          columns={columns}
          dataSource={data?.data || []}
        />
      </div>
    </div>
  );
}

export default Wiki;
