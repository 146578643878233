import { AuthProvider } from '@pankod/refine-core';

import { ACCESS_TOKEN } from 'utils/constants';
import { getToken, setToken, removeToken } from 'utils/functions';

import { API_LOGIN } from 'api';
import { IUser } from 'models';
import resource from 'resource';

import { axiosInstance } from './utils';

export const authProvider: AuthProvider = {
  login: async (payload) => {
    const { data } = await axiosInstance.post(API_LOGIN, payload);

    const response: IResponse<IUser> = data;

    if (response.meta.error) {
      return Promise.reject();
    }

    setToken(ACCESS_TOKEN, response.result.access_token);

    return Promise.resolve('/');
  },
  logout: () => {
    removeToken(ACCESS_TOKEN);
    return Promise.resolve(resource.ADMIN_LOGIN);
  },
  checkError: () => Promise.resolve(),
  checkAuth: async () => {
    if (!getToken(ACCESS_TOKEN)) {
      return Promise.reject();
    }

    axiosInstance.defaults.headers.common = {
      Authorization: `Bearer ${getToken(ACCESS_TOKEN)}`,
    };

    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: () => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (!token) {
      return Promise.reject();
    }

    return Promise.resolve(token);
  },
};
